import './GalleryFullBody.css';
import '../../StaticElement/Static.css';
import { useRef, useEffect, useReducer } from 'react';
import axios from 'axios';
import { t } from 'i18next';

function reducer(state, action){
    switch (action.type) {
        case 'setPhotoKitchen':
            return{...state, PhotoKitchen: action.payload}
        case 'setPhotoInterior':
            return{...state, PhotoInterior: action.payload}
        case 'setPhotoBar':
            return{...state, PhotoBar: action.payload}
        case 'setPhotoArr':
            return{...state, PhotoArr: action.payload}
    
        default:
            return state
    }
}
export default function GalleryFullBody(){
    let [data, dispatch] = useReducer(reducer, {PhotoKitchen:[{id: 0, image: '', name: ''}], PhotoInterior:[{id: 0, image: '', name: ''}], PhotoBar:[{id: 0, image: '', name: ''}], PhotoArr:[]})
    let NavActive = useRef();

    useEffect(() => {
        let idRest=0;
        if(data.PhotoArr.length !== 0) return;
        axios.get('http://admin.nrg.by/api/v1/restaurant/all')
            .then((res)=>{
                dispatch({type:'setPhotoKitchen', payload:res.data[idRest].restaurantimagecategory_set[1].restaurantimage_set})
                dispatch({type:'setPhotoInterior', payload:res.data[idRest].restaurantimagecategory_set[0].restaurantimage_set})
                dispatch({type:'setPhotoBar', payload:res.data[idRest].restaurantimagecategory_set[2].restaurantimage_set})
                dispatch({type:'setPhotoArr', payload:res.data[idRest].restaurantimagecategory_set[0].restaurantimage_set})
            })
    }, [data.PhotoArr, data.PhotoKitchen]);

    function Change(e){
        NavActive.current.classList.toggle('RestGalleryNavEl--Active');
        NavActive.current = e.target;
        NavActive.current.classList.toggle('RestGalleryNavEl--Active');
        switch (parseInt(NavActive.current.getAttribute('value'))) {
            case 2:
                dispatch({type:'setPhotoArr', payload:data.PhotoKitchen})       
                break;
            case 1:
                dispatch({type:'setPhotoArr', payload:data.PhotoInterior})               
            break;
            case 3:
                dispatch({type:'setPhotoArr', payload:data.PhotoBar})               
            break;        
            default:
                break;
        }
    }

    return(
        <div className='GalleryFullCont'>
            <div className='RestGalleryTitle'>
                <div>{t("Menu.gallery").toUpperCase()}</div>
                <hr color='Black' width='30%' style={{margin:'0px', border:'0.01px black solid', marginTop:'1vw'}}/>
            </div>
            <div className='RestGalleryNavCont'>
                <div ref={NavActive} className='RestGalleryNavEl RestGalleryNavEl--Active' onClick={Change} value='1'>{t("Menu.Interior")}</div>
                <div className='RestGalleryNavEl' onClick={Change} value='2'>{t("Menu.Kitchen")}</div>
                <div className='RestGalleryNavEl' onClick={Change} value='3'>{t("Menu.Bar")}</div>
            </div>
            <div className='GalleryFullPhotos'>
                {data.PhotoArr.map((item, i)=><img src={'http://admin.nrg.by' + item.image} alt={item.name} key={item.id}/>)}
            </div>
        </div>
    )
}