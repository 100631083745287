import './MenuEatBody.css';
import '../../StaticElement/Static.css';
import { useState, useEffect } from 'react';
import MenuEatCardTwo from './MenuEatCardTwo';
import i18next, { t } from 'i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function MenuEatBody(){ 
    const params = useParams();
    const [Menu, setMenu] = useState(0);
    const [dishes, setdishes] = useState([{dishes:[]},{dishes:[]},{dishes:[]},{dishes:[]},{dishes:[]},{dishes:[]},{dishes:[]},{dishes:[]},{dishes:[]},{dishes:[]},]);

    useEffect(() => {
        let idRest=1;
        if(dishes[0].dishes.length !== 0)return;
        axios.get(`http://admin.nrg.by/api/v1/restaurant/${idRest}/dishes`)
            .then((res)=>{
                setdishes(res.data.dishescategory_set);
            })
    }, [params.id, dishes]);

    return(
        <div className='MenuEatBodyCont'>
            <div className='MenuEatBodyTitle'>
                <div>{t("Menu.menu").toUpperCase()}</div>
                <hr color='Black' width='50%' style={{margin:'0px', border:'0.01px black solid'}}/>
            </div>
            <div className='MenuEatBodyButtonCont'>
                {dishes.map((item, i)=><div className='MenuEatBodyButton' key={i} onClick={() => setMenu(i)}>{i18next.language === 'ru' ? item.name_ru : item.name_en}</div>)}
            </div>
            <MenuEatCardTwo Name={i18next.language === 'ru' ? dishes[Menu].name_ru : dishes[Menu].name_en} cards={dishes[Menu].dishes}/>
        </div>
    )
}