import { Navigate, Route, Routes } from 'react-router-dom';
import GalleryFull from '../GalleryFull/GalleryFull';
import MenuEat from '../MenuEat/MenuEat';
import RestTemplate from '../RestTemplate/RestTemplate';
import './App.css';

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/RU'/>}/>
      <Route path='/:lang' element={
        <RestTemplate
          HeaderImgArr={[require('../StaticElement/Image/TheView/TheViewHeaderImg1.webp'), require('../StaticElement/Image/TheView/TheViewHeaderImg2.webp'),require('../StaticElement/Image/TheView/TheViewHeaderImg3.webp')]}
          MenuPhoto={require('../StaticElement/Image/TheView/MenuPhoto.webp')}
          GalleryKitchen={[require('../StaticElement/Image/TheView/Kitchen_1.webp'), require('../StaticElement/Image/TheView/Kitchen_2.webp'), require('../StaticElement/Image/TheView/GalleryKitchenLogo.png'), require('../StaticElement/Image/TheView/Kitchen_3.webp'), require('../StaticElement/Image/TheView/Kitchen_4.webp'), require('../StaticElement/Image/TheView/Kitchen_5.webp')]}
          GalleryInterior={[require('../StaticElement/Image/TheView/Interior_1.webp'), require('../StaticElement/Image/TheView/Interior_2.webp'), require('../StaticElement/Image/TheView/Interior_3.webp'), require('../StaticElement/Image/TheView/Interior_4.webp'), require('../StaticElement/Image/TheView/Interior_5.webp'), require('../StaticElement/Image/TheView/GalleryInteriorLogo.png'), require('../StaticElement/Image/TheView/Interior_6.webp')]}
          GalleryBar={[require('../StaticElement/Image/TheView/Bar_1.webp'), require('../StaticElement/Image/TheView/Bar_2.webp'), require('../StaticElement/Image/TheView/Bar_3.webp'), require('../StaticElement/Image/TheView/Bar_4.webp'), require('../StaticElement/Image/TheView/Bar_5.webp'), require('../StaticElement/Image/TheView/Bar_6.webp'), require('../StaticElement/Image/TheView/Bar_7.webp'), require('../StaticElement/Image/TheView/Bar_8.webp')]}
          ContactMap='https://yandex.ru/map-widget/v1/?um=constructor%3A76805615d47fd06b16cdb54e138c339fa200fd0a2629e49a263fab8fa1db2731&amp;source=constructor'
          ContactMail='theview@nrg.by'
          ContactTel='+375 44 702 8888'
          Inst='https://www.instagram.com/theview_restaurant/?igshid=YmMyMTA2M2Y%3D'
          Link='TheView'
          Tour={true}
          Menu={true}
          Bar={true}
          TourImg={require('../StaticElement/Image/TheView/tourimg.jpg')}
          TourSource='https://yandex.by/maps/org/the_view/1331580508/?l=stv%2Csta&ll=27.549934%2C53.907782&panorama%5Bdirection%5D=0.739099%2C-9.670566&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=27.549891%2C53.907777&panorama%5Bspan%5D=101.142682%2C47.642760&z=19.81'
          Relax='https://the-view.relax.by/'
        />
      }/>
      <Route path='/Menu:lang' element={<MenuEat/>}/>
      <Route path='/Gallery:lang' element={<GalleryFull/>}/>
    </Routes>
  );
}